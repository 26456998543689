import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from 'components/_settings/layout';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { Content } from 'components/pages/case-study/content';
import { Intro } from 'components/pages/case-study/intro';
import { Review } from 'components/pages/case-study/review';
import { buildPageMetadata } from 'utils/data-builders';
import { extractItems } from 'utils/extract-items';

const CaseStudy = (props) => {
  const {
    data: { wpPage },
  } = props;
  const { content, acf } = wpPage;
  const contentItems = extractItems(acf, 'section_2_item');
  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Header withoutActions={!acf.showHeader} theme="light" />
      <Intro
        label={acf.section1TextAboveTitle}
        title={acf.section1Title}
        description={acf.section1Description}
        buttonText={acf.section1ButtonText}
        buttonFileUrl={acf.section1ButtonFile.guid}
        illustration={{
          svg: acf.section1Illustration,
          title: acf.section1IllustrationTitle,
          description: acf.section1IllustrationDescription,
        }}
      />
      <Review avatar={acf.reviewAvatar} text={acf.reviewText} author={acf.reviewAuthor} />
      <Content content={content} items={contentItems} />
      {acf.showFooter && <Footer />}
    </Layout>
  );
};

export default CaseStudy;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      content
      acf {
        section1TextAboveTitle
        section1Title
        section1Description
        section1ButtonText
        section1ButtonFile {
          guid
        }
        section1Illustration
        section1IllustrationTitle
        section1IllustrationDescription

        reviewAvatar {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 89) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        reviewText
        reviewAuthor
        section2Item1Title
        section2Item1Value
        section2Item2Title
        section2Item2Value
        section2Item3Title
        section2Item3Value

        formTitle
        formDescription

        showHeader
        showFooter
      }
    }
  }
`;
