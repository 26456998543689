import * as React from 'react';

import { Container, Avatar, Content, Text, Author, Block } from './case-study-review.styles';

export const Review = (props) => {
  const { avatar, text, author } = props;

  return (
    <Container>
      <Block>
        <Avatar fluid={avatar.localFile.childImageSharp.fluid} />
        <Content>
          <Text>{text}</Text>
          <Author>{author}</Author>
        </Content>
      </Block>
    </Container>
  );
};
