import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

import { media } from '../../../_settings/variables';
import { Container as SharedContainer } from '../../../shared/container';

export const Container = styled(SharedContainer)`
  margin-top: -80px;
  margin-bottom: 100px;
`;

export const Block = styled.div`
  display: flex;
  position: relative;
  border-radius: 16px;
  box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
  background-color: #ffffff;
  padding: 40px 50px 45px;

  ${media.sm} {
    display: block;
    text-align: center;
  }
`;

export const Avatar = styled(GatsbyImage)`
  margin-right: 32px;
  width: 89px;
  height: 89px;
  flex-shrink: 0;

  ${media.sm} {
    margin: 0 auto 16px;
  }
`;

export const Content = styled.div``;

export const Text = styled.div`
  line-height: 1.6;
  margin-bottom: 12px;
`;

export const Author = styled.div`
  color: #5d7ad8;
  font-size: 16px;
  font-weight: 500;
`;
